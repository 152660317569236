@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
    font-weight: 700; 
    font-style: normal;
    font-display: swap;
}
